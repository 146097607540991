
export function mapCourseProgressData (courseProgress) {
  const { Course, title, icon: courseIcon } = courseProgress;
  if (!Course) { return null; }
  const { Application: { name, logo, icon }, ApplicationUid } = Course || { Application: { name: title, icon: courseIcon } };
  courseProgress.icon = logo || icon;
  const addObject = {
    ApplicationUid
  };
  const object = (() => {
    switch (name) {
    case 'Coursera':
      return mapCourseraCourseProgressData(courseProgress);
    case 'READTHEORY':
      return mapReadTheoryCourseProgressData(courseProgress);
    case 'TypingClub':
      return mapTypingClubCourseProgressData(courseProgress);
    case 'Khan Academy':
      return mapKhanCourseProgressData(courseProgress);
    case 'Canvas':
      return mapCanvasCourseProgressData(courseProgress);
    case 'GCF Global':
      return mapGCFCourseProgressData(courseProgress);
    case 'Moodle':
      return mapCanvasCourseProgressData(courseProgress);
    case 'NABTU':
      return mapNABTUCourseProgressData(courseProgress);
    case 'NCCERConnect':
      return mapNCCERCourseProgressData(courseProgress);
    case 'EssentialEd':
      return mapEssentialEdCourseProgressData(courseProgress);
    case 'Burlington English':
      return mapBurlingtonCourseProgressData(courseProgress);
    case 'ACCI':
      return mapACCICourseProgressData(courseProgress);
    case 'Edmentum':
      return mapEdmentumCourseProgressData(courseProgress);
    case 'ICEV':
      return mapIcevCourseProgressData(courseProgress);
    case 'Edgenuity':
      return mapEdgenuityCourseProgressData(courseProgress);
    case 'Pearson':
      return mapPearsonCourseProgressData(courseProgress);
    case 'Star Renaissance':
      return mapStarRenaissanceCourseProgressData(courseProgress);
    case 'Penn Foster':
      return mapPennFosterCourseProgressData(courseProgress);
    case 'Rebound AllStars':
      return mapReboundAllStarsCourseProgressData(courseProgress);
    case 'Promising People':
      return mapPromisingPeopleCourseProgressData(courseProgress);
    case 'Ascend Math':
      return mapAscendMathCourseProgressData(courseProgress);
    case 'KA Lite':
      return mapKALiteCourseProgressData(courseProgress);
    case 'IXL Learning':
      return mapIXLLearningCourseProgressData(courseProgress);
    case 'Reading Horizon':
      return mapReadingHorizonProgressData(courseProgress);
    case 'ACT WorkKeys':
      return mapActWorkKeysProgressData(courseProgress);
    case 'Ed2Go':
      return mapEd2GoProgressData(courseProgress);
    case 'Simply Coding':
      return mapSimplyCodingProgressData(courseProgress);
    case 'American High School':
      return mapAmericanHighSchoolProgressData(courseProgress);
    case 'W3Schools':
      return mapW3SchoolsProgressData(courseProgress);
    case 'Acceleron Learning':
      return mapAcceleronLearningProgressData(courseProgress);
    case 'Apex':
      return mapApexLearningProgressData(courseProgress);
    case 'Metrix Learning':
      return mapMetrixLearningProgressData(courseProgress);
    case '2nd Opp':
      return map2ndOppProgressData(courseProgress);
    case 'Duolingo':
      return mapDuolingoProgressData(courseProgress);
    case 'CareerSafe':
      return mapCareerSafeProgressData(courseProgress);
    case 'Lion Heart':
      return mapLionHeartProgressData(courseProgress);
    case 'Graduation Alliance':
      return mapGraduationAllianceProgressData(courseProgress);
    case 'Skool':
      return mapSkoolProgressData(courseProgress);
    default:
      return {};
    }
  })();
  return { ...object, ...addObject };
}

function mapGCFCourseProgressData (courseProgress) {
  const { status, percentage, name, CourseId: id, createdAt, icon, progressUpdatedAt, rawData } = courseProgress;
  return {
    percentage,
    displayName: name,
    id,
    status,
    date: new Date(createdAt),
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    icon,
    progressUpdatedAt
  };
}

function mapCourseraCourseProgressData (courseProgress) {
  const {
    Course,
    date,
    status,
    percentage,
    id,
    icon,
    name, updatedAt,
    rawData, progressUpdatedAt
  } = courseProgress;
  const { title } = Course || { title: '' };
  return {
    date,
    icon,
    id,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name || title,
    status,
    updatedAt,
    percentage
  };
}

function mapEssentialEdCourseProgressData (courseProgress) {
  const {
    Course,
    status,
    percentage,
    id,
    name,
    updatedAt,
    enrolledAt,
    progressUpdatedAt,
    rawData
  } = courseProgress;
  const { title } = Course || { title: '' };
  return {
    date: enrolledAt && new Date(enrolledAt),
    icon: Course.icon,
    id,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name || title,
    status,
    updatedAt,
    percentage,
    progressUpdatedAt
  };
}
function mapNABTUCourseProgressData (courseProgress) {
  const { Course, enrolledAt, status, percentage, id, name, updatedAt, icon, rawData, progressUpdatedAt } = courseProgress;
  const { title } = Course || { title: '' };
  return {
    date: enrolledAt && new Date(enrolledAt),
    id,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name || title,
    icon,
    // iconStyle: { backgroundColor: 'black' },
    status,
    updatedAt,
    percentage
  };
}

function mapNCCERCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapReadTheoryCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, percentage, progressUpdatedAt, rawData } = courseProgress;
  console.log('');

  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapTypingClubCourseProgressData (courseProgress) {
  const { id, updatedAt, icon, rawData: { avg_accuracy: accuracy, avg_wpm: wpm, stars, score, progress, lastActivity }, status, progressUpdatedAt } = courseProgress;

  const displayName = `TypingClub - Accuracy: ${accuracy}, Speed: ${wpm} WPM, Stars: ${stars}, Score: ${score}`;
  return {
    date: updatedAt,
    displayName,
    id,
    lastActivity: lastActivity || progressUpdatedAt,
    icon,
    status,
    percentage: progress,
    isUpdateNotActivity: true
  };
}

function mapKhanCourseProgressData (courseProgress) {
  // const { id, status, updatedAt, icon, percentage, rawData: { course, activity, date, level, time } } = courseProgress
  const { status, icon, percentage, rawData: { course, activity, date, lastActivity }, progressUpdatedAt } = courseProgress;
  const khanDate = date && new Date(date);
  const displayName = `${course} - ${activity}`;
  return {
    date: khanDate,
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName,
    updatedAt: khanDate,
    percentage,
    progressUpdatedAt
  };
}

function mapCanvasCourseProgressData (courseProgress) {
  /* eslint-disable camelcase */
  const { id, enrolledAt, icon, rawData: { course_code, name, course_progress, lastActivity }, progressUpdatedAt } = courseProgress;
  const percentage = (course_progress.requirement_completed_count / course_progress.requirement_count) * 100;
  const displayName = `${name} ${name !== course_code ? course_code : ''}`;
  const date = enrolledAt && new Date(enrolledAt);
  return {
    date,
    status: percentage === 100 ? 'COMPLETED' : percentage === 0 ? 'INACTIVE' : 'STARTED',
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName,
    percentage,
    id
  };
}

function mapBurlingtonCourseProgressData (courseProgress) {
  const { name, createdAt, progressUpdatedAt, icon, percentage, status, rawData: { courseType, lastActivity } } = courseProgress;
  return {
    date: new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt,
    ...(courseType !== 'books' && { percentage })
  };
}

function mapACCICourseProgressData (courseProgress) {
  const { name, createdAt, icon, percentage, progressUpdatedAt, status, rawData } = courseProgress;
  return {
    date: new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapEdmentumCourseProgressData (courseProgress) {
  const { name, icon, percentage, status, rawData, createdAt, progressUpdatedAt } = courseProgress;

  return {
    date: new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapIcevCourseProgressData (courseProgress) {
  const { name, createdAt, icon, percentage, status, progressUpdatedAt, rawData } = courseProgress;
  return {
    date: new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapEdgenuityCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, percentage, status, progressUpdatedAt, rawData } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapPearsonCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, percentage, status, progressUpdatedAt, rawData } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapStarRenaissanceCourseProgressData (courseProgress) {
  const { name, createdAt, icon, status, progressUpdatedAt, rawData } = courseProgress;
  return {
    date: new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapPennFosterCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, percentage, progressUpdatedAt, rawData } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapReboundAllStarsCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, percentage, progressUpdatedAt, rawData } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapPromisingPeopleCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, percentage, progressUpdatedAt, rawData } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    percentage,
    progressUpdatedAt
  };
}

function mapAscendMathCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, progressUpdatedAt, rawData } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: (rawData && rawData.lastActivity) || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapKALiteCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapIXLLearningCourseProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapReadingHorizonProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapActWorkKeysProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapEd2GoProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapSimplyCodingProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapW3SchoolsProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapAmericanHighSchoolProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapAcceleronLearningProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapApexLearningProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapMetrixLearningProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function map2ndOppProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapDuolingoProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapCareerSafeProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapLionHeartProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapGraduationAllianceProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}

function mapSkoolProgressData (courseProgress) {
  const { name, enrolledAt, createdAt, icon, status, lastActivity, progressUpdatedAt } = courseProgress;
  return {
    date: enrolledAt || new Date(createdAt),
    status,
    icon,
    lastActivity: lastActivity || progressUpdatedAt,
    displayName: name,
    progressUpdatedAt
  };
}
